import styled, {css} from 'styled-components'
import {headerBackgroundColor} from '../../../styles/base/base-colors.scss';
import {websitePageWidth} from '../../../styles/base/base-layout.scss';

//todo move these out into global
const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '710px',
    laptop: '1070px',
    websitePageWidth: websitePageWidth,
    laptopL: '1440px',
    desktop: '2560px'
}

export const HEADER_SWITCH_VIEWPORT = '(max-width: 990px)'

const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    websitePageWidth: `(max-width: ${size.websitePageWidth})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
};

export const HeaderContainer = styled.div`

  color: black;
  padding: 17px 6px;
  border-bottom: ${props => props.transparentHeader ? '1px solid #be9600' : 'none'};
  position: ${props => props.transparentHeader ? 'absolute' : 'relative'}
  z-index: 1;
  background-color: ${props => props.transparentHeader ? 'rgba(255,255,255,0.85)' : headerBackgroundColor};
  display: flex;
  justify-content: flex-start;
  -webkit-box-shadow: ${props => props.transparentHeader ? '0' : '0 4px 6px -6px #222'};
  -moz-box-shadow: ${props => props.transparentHeader ? '0' : '0 4px 6px -6px #222'};
  box-shadow: ${props => props.transparentHeader ? '0' : '0 4px 6px -6px #222'};
  width: ${props => props.transparentHeader ? props.width : 'auto'};

  .headerContent {
    display: grid;
    grid-template-columns: max-content 1fr;
    width: 100%;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    max-width: ${websitePageWidth};
    @media screen and ${HEADER_SWITCH_VIEWPORT} {
      margin-left: 70px;
    }

    .logoContainer {
      justify-content: flex-start;

      a {
        color: #000;
        text-decoration: none;
        height: 100%;
        display: block;

        #logo, #logoMobile {
          height: 160px;
          display: flex;
          //width: 100%;
          @media screen and (max-width: 562px) {
            height: auto;
            width: 90%;
          }
        }

        #logoMobile {
          display: none;
        }
      }

      .active {
        .tkas {
          &::before {
            color: #27a3bf;
          }
        }
      }
    }

    .linksContainer {
      justify-content: center;
      padding: 0 20px;
      display: grid;
      align-content: center;
      align-items: center;
      @media screen and ${HEADER_SWITCH_VIEWPORT} {
        display: none;
      }

      .linksTilesContainer {
        height: 100%;
      }
    }
  }


}
`
export const MenuTileStyle = styled.div`
  width: ${props => props.tileWidth}
  padding: ${props => props.tilePadding} img {
    width: 100%;
    &.circlePic {
      border-radius: 50%;
    }
  }
`
