import styled from 'styled-components'

export const BackToTopSection = styled.div`
    .ScrollUpButton__Toggled {
        opacity: 1;
        &:hover {
            opacity: 1;
        }
    }
`
