import styled, {css} from 'styled-components'
import {
    linkTileActiveBorderUnderline,
    headerLinkTileActiveColor,
    linkTileHoverUnderline,
    linkTileBorderRadius,
    linkTileBackgroundColor,
    headerLinkTileColor,
    headerLinkTileHoverColor
} from '../../../../styles/base/base-colors.scss';
import {mobileLinkWidth} from '../../../../styles/base/base-layout.scss';
import {fullHeaderFont} from '../../../../styles/base/base-typography.scss';

//todo move these out into global
const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '710px',
    laptop: '1070px',
    laptopL: '1440px',
    desktop: '2560px'
}

const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
};

export const LinksTilesContainerStyle = styled.div`
  font: ${fullHeaderFont};

  > div {
    display: flex;
  }

  .horizontalContainer {
    flex-direction: row;
    height: 100%;
    display: flex;
  }

  .verticalContainer {
    flex-direction: column;
    height: 100%;
    display: flex;

    .linkTile {
      > div {
        width: ${mobileLinkWidth};

      }
    }
  }

  .linkTile {
    display: flex;
    font-size: 16px;
    font-family: CoreSansA45Regular;

    > div {
      cursor: pointer;

      a {
        color: ${props => props.transparentHeader ? '#000' : headerLinkTileColor};
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;

        &:not(.active):hover {
          color: ${props => props.transparentHeader ? '#000' : headerLinkTileHoverColor} !important;
        }
      }

      &.active {
        a {
          color: ${props => props.transparentHeader ? '#000' : headerLinkTileActiveColor} !important;
        }

      }

      &:hover {
        box-shadow: ${linkTileHoverUnderline};
      }

      padding: 14px 8px;
      margin: 1px 4px;
      align-self: center;
      border-radius: ${props => props.linkBorderRadius ? props.linkBorderRadius : 0};
      background-color: ${props => props.transparentHeader ? 'transparent' : linkTileBackgroundColor};


    }


  }
`
