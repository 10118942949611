import React, {useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import history from '../../../history'

require ('./SearchField.scss')

/**
 *
 * SearchField
 *
 * responsible for the global search text and functions
 *
 *
 * @returns {JSX.Element}
 *
 *
 */

const SearchField = ({onSuccess ,isModal}) => {
  const [searchText, setSearchText] = useState('');

  const searchNow = () =>{
    //clear search
    setSearchText('');
    //push off to search page and let it handle the rest
    history.push(`/search/${searchText}`)
  }

  const handleChange = (e) => {
    setSearchText(e.target.value );
  };
  const handleKeyDown = (e) => {
    if(e?.keyCode === 13)handleClickSearch()
  };

  const handleClickSearch = () => {
    if (typeof onSuccess === "function")onSuccess()
    searchNow()

  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
      <div className='searchBoxContainer' style={{position:`${isModal ? 'inherit' : 'absolute'}`}}>
        <OutlinedInput
          id="outlined-adornment"
          type='start'
          label={null}
          value={searchText}
          onChange={handleChange}
          placeholder='Search'
          classes={
            { root: 'searchField' }
          }
          inputProps={{
            onKeyDown:handleKeyDown
          }}
          startAdornment={
            <InputAdornment position='start'>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickSearch}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
  )
}

export default SearchField
