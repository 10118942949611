import React, {useEffect} from 'react'
import {gsap} from "gsap"
import loader from './data.json'
import anime from 'animejs'

require('./big-logo-loader.scss')

const BigLogoLoader = props => {


    return <div className='loadingContainer'>
        <img src='../../../../assets/img/components/loader/loader.gif' alt='Loading'/>
        <div className="subTitle-m">Loading content</div>
    </div>;

}

export default BigLogoLoader
