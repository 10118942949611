import * as actionTypes from './action-types'

export const toggleHamburger = props => {
  // console.log('[redux/actions/account.js] setSelectedAccount: action', payload)
  // console.log('gets here')
  return (dispatch, getState) => {

    dispatch({
      type: actionTypes.TOGGLE_HAMBURGER,
      data:props
    })

  }
}
export const closeHamburger = () => {
  // console.log('[redux/actions/account.js] setSelectedAccount: action', payload)
  // console.log('gets here')
  return (dispatch, getState) => {

    dispatch({
      type: actionTypes.CLOSE_HAMBURGER,
    })

  }
}
