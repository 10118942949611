import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { signIn, signOut } from '../../../../auth/actions'
import {toggleHamburger} from "../redux/action"
import HandleLinkRender from '../../LinkRender'
import {LinksTilesContainerStyle} from './header-link-tiles-styles'

const HeaderLinkTiles = ({links, linksDirection,onClickFunction,transparentHeader}) =>{

  //todo below is duplication LinkRender, need to find a better way

  const [linkClicked, setLinkClicked] = useState(false)
  const myPath = window.location.pathname
  const [stateLinks, setLinks] = useState(links)

  const linkChange = useHistory()
//hook to check if link clicked
  useEffect(() => {
    // console.log(onClickFunction)
    // if(onClickFunction) onClickFunction()
    return history.listen((location) => {
      setLinks(null)
      setTimeout(()=>{
        setLinks(links)
      }, 10)


    })
  },[linkChange])

  const linkClickedFunc = props =>{
    setLinkClicked(true)
    if(typeof(onClickFunction) !== 'undefined' ) onClickFunction()
  }

  let history = useHistory()

  // useEffect(() => {
  //   if(!stateLinks) return
  //   linksDirection === 'verticalContainer' ?
  //     gsap.fromTo(".linkTile", {x:-20}, {stagger: .05, x:0,  duration: .6})
  //     : gsap.fromTo(".linkTile", {y:-20}, {stagger: .1, y:0,  duration: .6})
  // }, [stateLinks])



  return (
    <LinksTilesContainerStyle
      transparentHeader={transparentHeader}
    >
      {stateLinks && (
        <div className={linksDirection}>
          {stateLinks.map((link,index)=>{
            return(
              <div className={`linkTile ${link.linkDetails.specialClass || ''}${myPath === link.linkDetails.url ? 'active' : ''}`}
                   key={link.linkDetails.url}
                   onClick={() => {
                     if(link.internalLink)
                     {history.push(link.linkDetails.url)
                       linkClickedFunc()
                     }
                     else{
                       if(link.linkDetails.newtab === true){
                         const win = window.open(link.linkDetails.url, '_blank')
                         if (win != null) {
                           win.focus()
                         }
                       }
                       else{
                         window.location.href = link.linkDetails.url
                       }
                     }
                   }}
              >
                <HandleLinkRender
                  link={link}
                  //afterClick={()=>()}
                />

              </div>
            )
          })}
        </div>
      )}

    </LinksTilesContainerStyle>
  )

}
const mapStateToProps = state =>{

  return {
    isSignedIn: state.auth.isSignedIn,
    hamburgerStatus: state.minimenu.hamburgerStatus
  };
}

const mapDispatchToProps = {
  toggleHamburger,
  signIn,
  signOut
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinkTiles);
