import * as actionTypes from '../actions/actionTypes.js'
import {headerLinks} from '../../app-config/meta-data'


const initialState = {
    header: {
        links: headerLinks,
        headerIsLoading:true
    }
}

const sel = (state = initialState, action) => {
    switch (action.type) {

        default: {
            // console.log('[redux/reducers/auth.js]', state)
            return state
        }
    }
}

export default sel
