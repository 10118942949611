import React from 'react'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
import history from '../history'
import {metadata} from '../app-config'

import {
    Landing,
    Contact,
    About,
    BlogsPage,
    BlogPage,
    PackagePage,
    PackagesPage,
} from '../loader'

const Routes = () => {
    return (
        <div className="bodyContentContainer">
            <Router history={history}>
                <div>
                    <Switch>
                        {/*<Redirect from="/" exact to={metadata.home.route} />*/}
                        <Route path={metadata.home.route} exact component={Landing}/>
                        <Route path={metadata.contact.route} exact component={Contact}/>
                        <Route path={metadata.about.route} exact component={About}/>
                        <Route path={metadata.blogs.route} exact component={BlogsPage}/>
                        <Route path={metadata.blog.route} exact component={BlogPage}/>
                        <Route path={metadata.resources.route} exact component={PackagesPage}/>
                        <Route path={metadata.resource.route} exact component={PackagePage}/>
                    </Switch>
                </div>
            </Router>
        </div>
    );
};
export default Routes;
