import React, {Suspense, Fragment} from 'react'
import Loading from './components/common/loading'
import {Helmet} from "react-helmet";
import {metadata} from "./app-config"
import Header from './components/common/header/header'

const LazyHOC = props => {
    return (
        <Fragment>
            {!props.noHeader && (
                <Header
                    transparentHeader={props.transparentHeader || false}
                />
            )}
            {props.children}
        </Fragment>
    );
}
const HelmetHOC = props => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description}/>
        </Helmet>
    )
}

const LazyLanding = React.lazy(() => import('./pages/home'));
const LazyContact = React.lazy(() => import('./pages/contact'));
const LazyAbout = React.lazy(() => import('./pages/about'));
const LazyBlogsPage = React.lazy(() => import('./pages/blogs'));
const LazyBlogPage = React.lazy(() => import('./pages/blogs/blog'));
const LazyPackagesPage = React.lazy(() => import('./pages/packages'));
const LazyPackagePage = React.lazy(() => import('./pages/packages/package'));


function Landing() {
    return (
        <Suspense fallback={<Loading message={metadata.home.description}/>}>
            {HelmetHOC(metadata.home)}
            <LazyHOC><LazyLanding/></LazyHOC>
        </Suspense>
    )
}

function Contact() {
    return (
        <Suspense fallback={<Loading message={metadata.contact.description}/>}>
            {HelmetHOC(metadata.contact)}
            <LazyHOC><LazyContact/></LazyHOC>
        </Suspense>
    )
}

function About() {
    return (
        <Suspense fallback={<Loading message={metadata.about.description}/>}>
            {HelmetHOC(metadata.about)}
            <LazyHOC><LazyAbout/></LazyHOC>
        </Suspense>
    )
}

function BlogsPage() {
    return (
        <Suspense fallback={<Loading message={metadata.blogs.description}/>}>
            {HelmetHOC(metadata.blogs)}
            <LazyHOC><LazyBlogsPage/></LazyHOC>
        </Suspense>
    )
}

function BlogPage() {
    return (
        <Suspense fallback={<Loading message={metadata.blog.description}/>}>
            {HelmetHOC(metadata.blog)}
            <LazyHOC><LazyBlogPage/></LazyHOC>
        </Suspense>
    )
}

function PackagesPage() {
    return (
        <Suspense fallback={<Loading message={metadata.resources.description}/>}>
            {HelmetHOC(metadata.resources)}
            <LazyHOC><LazyPackagesPage/></LazyHOC>
        </Suspense>
    )
}

function PackagePage() {
    return (
        <Suspense fallback={<Loading message={metadata.resource.description}/>}>
            {HelmetHOC(metadata.resource)}
            <LazyHOC><LazyPackagePage/></LazyHOC>
        </Suspense>
    )
}

export {
    Landing,
    Contact,
    About,
    BlogsPage,
    BlogPage,
    PackagesPage,
    PackagePage,
}
