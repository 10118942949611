import React, { Fragment,useState, useEffect,useRef } from 'react'
import HeaderLinkTiles from '../../header-link-tiles/HeaderLinkTiles'
require ('./MenuContent.scss')


const MenuContent = props =>{

  const {links,image, onClickFunction} = props

  return (
    <Fragment>
      <div className="contentContainer">
     {image && (
       <img src={image} className='headerImg' />
     )}
        <div>
          {links && (
            <HeaderLinkTiles
              links={links}
              linksDirection='verticalContainer'
              onClickFunction={onClickFunction}
            />
            )}
        </div>
      </div>
    </Fragment>
  )
}

export default MenuContent
