import * as actionTypes from './action-types'

const initialState = {
  featureServices: {
    data:null,
    lastFetch:0,
  },
  featureBlogs: {
    data:null,
    lastFetch:0,
  },
  featurePackages: {
    data:null,
    lastFetch:0,
  },
  featureFaqs: {
    data:null,
    lastFetch:0,
  },
  featureProducts: {
    data:null,
    lastFetch:0,
  },
  featureTechnicalDocuments: {
    data:null,
    lastFetch:0,
  },
  allArticles:{
    loading:false,
    lastFetch:0,
    Error:null,
  }
}



const featureArticles = ( state = initialState, action ) => {
  switch(action.type){

    case actionTypes.GET_FEATURE_SERVICES_SUCCESS:
      return{
        ...state,
        featureServices: {
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break


    case actionTypes.GET_FEATURE_BLOGS_SUCCESS:
      return{
        ...state,
        featureBlogs: {
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break


    case actionTypes.GET_FEATURE_PACKAGES_SUCCESS:
      return{
        ...state,
        featurePackages: {
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break


    case actionTypes.GET_FEATURE_TECH_DOC_SUCCESS:
      return{
        ...state,
        featureTechnicalDocuments: {
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break


    case actionTypes.GET_FEATURE_FAQS_SUCCESS:
      return{
        ...state,
        featureFaqs: {
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break


    case actionTypes.GET_FEATURE_PRODUCTS_SUCCESS:
      return{
        ...state,
        featureProducts: {
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break
    case actionTypes.GET_FEATURE_ALL_REQUEST:
      return{
        ...state,
        allArticles: {
          loading:true,
        }
      }
      break
    case actionTypes.GET_FEATURE_ALL_FAIL:
      return{
        ...state,
        allArticles: {
          loading:false,
          data:action.payload,
          lastFetch:0,
          Error:action.error
        }
      }
      break
    case actionTypes.GET_FEATURE_ALL_SUCCESS:
      return{
        ...state,
        allArticles: {
          loading:false,
          lastFetch:Date.now()
        }
      }
      break

    case actionTypes.FEATURE_ARTICLES_RESET:
      return{
        ...initialState
      }
      break
}
  return state
}

export default featureArticles
