import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'

const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 3.7.1',
            url: `${metadata.blogs.route}/standard-3.7.1`,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 6.1.1',
            url: `${metadata.blogs.route}/standard-6.1.1`,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 6.2.1',
            url: `${metadata.blogs.route}/standard-6.2.1`,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 6.3.1',
            url: `${metadata.blogs.route}/standard-6.3.1`,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 6.4.1',
            url: `${metadata.blogs.route}/standard-6.4.1`,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 7.3.1',
            url: `${metadata.blogs.route}/standard-7.3.1`,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Standard 7.4.1',
            url: `${metadata.blogs.route}/standard-7.4.1`,
        },
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Scaffolded literacy resource to explain density',
            url: `${metadata.resources.route}/scaffolded-literacy-resource-to-explain-density`,
        },
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Scaffolded numeracy resource to explain scale models',
            url: `${metadata.resources.route}/scaffolded-numeracy-resource-to-explain-scale-models`,
        },
    },
    {
        internalLink: true,
        linkDetails: {
            label: 'Science teaching methods lesson plans',
            url: `${metadata.resources.route}/science-teaching-methods-lesson-plans`,
        },
    },
    {
        internalLink: true,
        linkDetails: {
            label: metadata.contact.linkName,
            url: metadata.contact.route,
        }
    },

]


const renderInternalNavLinks = ({linkType}) => {

    //render each internal link that is in the headerLinks array
    let footerNavLinks = []
    const standardsLinks = headerLinks.filter((link) => link.linkDetails.url.startsWith('/standards'))
    const resourcesLinks = headerLinks.filter((link) => link.linkDetails.url.startsWith('/resources'))
    const contactLink = [headerLinks[headerLinks.length - 1]]

    const links = linkType === 'standards'
        ? standardsLinks
        : linkType === 'resources'
            ? resourcesLinks
            : linkType === 'contact'
                ? contactLink
                : headerLinks;

    for (const navLink of links) {
        if (navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type: 'link'})
    }
    // console.log(footerNavLinks)
    return footerNavLinks
}


export const footerContent = {
    columns: [
        {
            content: [
                {
                    type: 'title',
                    value: 'Standards'
                },
                ...renderInternalNavLinks({linkType: 'standards'})
            ]
        },
        {
            content: [
                {
                    type: 'title',
                    value: 'Resources'
                },
                ...renderInternalNavLinks({linkType: 'resources'}),
                {
                    type: 'title',
                    value: '' //tactical space
                },
                {
                    type: 'title',
                    value: '' //tactical space
                },
                {
                    type: 'title',
                    value: 'Get in touch'
                },
                ...renderInternalNavLinks({linkType: 'contact'})
            ]
        },
        {
            content: [
                {
                    type: 'img',
                    specialClass: 'footerLogo',
                    value: {
                        src: `/${APP_CONFIG.FOOTER_IMAGE}`,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]

        }
    ]
}
