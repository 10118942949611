import { createStore, combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

//import storage from 'redux-persist/lib/storage' //defaults to localstorage fro web and AsyncStorage for the native
/*  redux persist storage persists the data into the local storage syncronously whenever there are changes to the redux state . The problem with localstorge is that the
    1. storage space is very limited
    2. Any operation here is syncronous.So the js thread has to wait till all the root data is persisted into the localstorage.
    3. The data is not indexed. So fetch of the data is v performant
    */

// Hence use the indexedDB storage using the localforage

import storage from 'localforage'

//Define the redux persist config
const persistConfig = {
    key: 'root',
    storage,
    //for now just persist only the canonhub content, persisting auth information leads to auth problems - not suggested
    whitelist: []
}

export const StoreManager = {
    store: null,
    reducerMap: {},
    registerReducers(newReducerMap) {
        if (
            Object.entries(this.reducerMap).length === 0 &&
            this.reducerMap.constructor === Object
        ) {
            this.reducerMap = newReducerMap
        } else {
            this.reducerMap = { ...this.reducerMap, ...newReducerMap }
            //console.log(this.store.getState())
            this.store.replaceReducer(combineReducers(this.reducerMap))
            //console.log(this.store.getState())
        }
    },

    createStore(...args) {
        // console.log(this.reducerMap)
        const reduxPersistReducer = persistReducer(
            persistConfig,
            combineReducers(this.reducerMap)
        )
        this.store = createStore(reduxPersistReducer, ...args)

        return this.store
    }
}

export default StoreManager
