import React from 'react'
import SearchField from './SearchField'
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
require ('./GlobalSearch.scss')

/**
 *
 * GlobalSearch
 *
 * handles the responsive rendering of SearchField which is the mechanics of the global search
 * responsiveness is done via scss/sass
 *
 */

const GlobalSearch = () => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className='desktopSearch'>
        <SearchField />
      </div>
      <div className='mobileSearch'>
        <IconButton aria-label="search" color="primary">
          <SearchIcon onClick={handleClickOpen}/>
        </IconButton>
        {open &&
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <DialogTitle>Search</DialogTitle>
          <DialogContent>
            <SearchField onSuccess={handleClose} isModal/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        }

      </div>

    </>

  )
}

export default GlobalSearch
