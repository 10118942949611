import React, {Fragment, useEffect, useState} from 'react'
import LinkTiles from './header-link-tiles'
import HeaderHamburgerMenu from './header-hamburger-menu'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {NavLink, useHistory} from 'react-router-dom'
import {headerLinks, metadata} from '../../../app-config'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ScienceIcon from '@mui/icons-material/Science';
import config from '../../../config.json'
import {getAllFeatureArticles} from '../../../content/feature-articles/action'
import axios from 'axios'
import StoreManager from '../../../redux/store-manager'
import reducers from '../../../redux/reducers'
import {HeaderContainer} from './header-styles'
import {useCurrentWidth} from '../../../utils/constants'


import {connect} from 'react-redux'
import GlobalSearch from '../GlobalSearch/GlobalSearch'

StoreManager.registerReducers(reducers)

require('./header.scss')

const Header = props => {
    const {
        headerLinks,
        transparentHeader
    } = props

    const [anchorEl, setAnchorEl] = React.useState();
    const [anchorEl2, setAnchorEl2] = React.useState();
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClick2 = (e) => {
        setAnchorEl2(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
    };
    const history = useHistory()

    // console.log(transparentHeader)

    const [darkMode, setDarkMode] = useState(false)
    //adding headerLinksState to refresh highlighted link clicked
    const [headerLinksState, setHeaderLinksState] = useState(headerLinks)
    const width = useCurrentWidth()

    //todo import strings from metadata
    const standardsLinks = headerLinks.filter((link) => link.linkDetails.url.startsWith('/standards'))
    const resourcesLinks = headerLinks.filter((link) => link.linkDetails.url.startsWith('/resources'))


    //start up loading
    useEffect(() => {

        const payload = {
            "width": window.innerWidth || document.body.clientWidth,
            "height": window.innerHeight || document.body.clientHeight
        }

        axios.post('/api/BI/', payload)
            .then(
                response => {
                    // console.log(response.data)
                }
            )
    }, [])

    //dark mode toggling
    useEffect(() => {
        if (!darkMode) return
        const element = document.getElementById("rfgdz")
        element.classList.toggle("dark-mode")

    }, [darkMode])


    return (
        <Fragment>
            <HeaderContainer
                transparentHeader={transparentHeader}
                width={`${width}px`}
            >
                {/*<HeaderContactDetails*/}
                {/*	primaryMobile={true}*/}
                {/*	email={true}*/}
                {/*/>*/}
                <HeaderHamburgerMenu
                    title={config.WEBSITE_NAME}
                    links={headerLinks}
                    image={config.HEADER_IMAGE}
                    lineColor={transparentHeader ? '#956f04' : 'black'}
                    placement="left"
                />
                <div className="headerContent">

                    <div className="logoContainer">
                        <NavLink
                            to={{pathname: metadata.home.route}}
                            title={metadata.home.title}
                            activeClassName="active"
                            onClick={() => {
                                setHeaderLinksState(null)
                                setTimeout(() => {
                                    setHeaderLinksState(headerLinks)
                                }, 200)
                            }}
                        >
                            {transparentHeader && (
                                <img id='logo' src={`/${config.HEADER_IMAGE_DARK}`} title={metadata.home.title}
                                     alt={metadata.home.title}/>
                            )}
                            {!transparentHeader && (
                                <img id='logo' src={`/${config.HEADER_IMAGE}`} title={metadata.home.title}
                                     alt={metadata.home.title}/>
                            )}
                            {/*<img id='logoMobile' src={`/${config.HEADER_MOBILE_IMAGE}`} title={metadata.home.title} alt={metadata.home.title} />*/}
                        </NavLink>
                    </div>
                    <div className="linksContent">
                        {standardsLinks.length && (
                            <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    style={{height: '40px', maxHeight: '40px', fontSize: '16px'}}
                                    endIcon={<KeyboardArrowDownIcon/>}
                                >
                                    Standards
                                </Button>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {standardsLinks.map((link, i) =>
                                        <MenuItem key={i}
                                                  style={{padding: '10px 30px'}}
                                                  onClick={() => history.push(link.linkDetails.url)}>{link.linkDetails.label}</MenuItem>
                                    )}

                                </Menu>
                            </div>
                        )}

                        {resourcesLinks.length && (
                            <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open2 ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open2 ? 'true' : undefined}
                                    onClick={handleClick2}
                                    style={{height: '40px', maxHeight: '40px', fontSize: '16px', cursor: 'pointer'}}
                                    endIcon={<KeyboardArrowDownIcon/>}
                                >
                                    Resources
                                </Button>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl2}
                                    open={open2}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {resourcesLinks.map((link, i) =>
                                        <MenuItem key={i}
                                                  style={{padding: '10px 30px', cursor: 'pointer'}}
                                                  onClick={() => history.push(link.linkDetails.url)}>{link.linkDetails.label}</MenuItem>
                                    )}

                                </Menu>
                            </div>
                        )}

                        <div className="linksContainer">
                            {headerLinksState && (
                                <LinkTiles
                                    links={[headerLinksState[headerLinksState.length - 1]]} //only last link
                                    linksDirection='horizontalContainer'
                                    transparentHeader={transparentHeader}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </HeaderContainer>
        </Fragment>

    )

}

const mapStateToProps = (state, ownProps) => {
    const headerLinks = state.navigation ? state.navigation.header.links : []
    return {
        headerLinks
    }
}

const mapDispatchToProps = {
    getAllFeatureArticles
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
// export default Header
