import authReducer from '../../auth/reducers/authReducer';
// import { combineReducers } from 'redux'
import navigation from './navigation.js'
import featureArticles from '../../content/feature-articles/reducers'

export default {
    auth: authReducer,
    navigation,
    featureArticles
}
