import axios from 'axios'
import history from '../history'
import { metadata } from '../app-config'
import *  as APP_CONFIG from '../config'

const forbiddenStatusCodes = [ 401, 403, 405, 406 ]


export const configureInterceptors = () => {
	/**
     * @name constructor
     * @desc intercept the outgoing http request and append the token (for Canon STS)
     * @desc calling them injectors cause i keep forgetting there interceptors
     * @param {Object} request - the request properties
     */



	axios.interceptors.request.use(
		(request) => {

			// console.log(window.location.hostname)

			if (window.location.hostname === 'localhost' ) {
				// add new environment
				// console.log(window.location.hostname)
				// console.log(request.url)
				const bla = request.url.split('/')
				// console.log(bla[1])
				if(`/${bla[1]}/` === APP_CONFIG.CONTENT_URL){
					request.url = `http://localhost/${request.url}`
					// console.log(request.url)
				}
				//request.url = request.url.replace()
			}

			return request
		},
		(error) => {
			console.error(error)
			return Promise.reject(error)
		}
	)
	// ---------------------------------------------
	//   HTTP INTERCEPTOR FOR AXIOS - RESPONSE
	// ---------------------------------------------
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			console.error(error)
			//todo actually fix this
			// if (error.response && forbiddenStatusCodes.includes(error.response.status))
			// 	history.push(metadata.accesserror.route)
			// else return Promise.reject(error)
		}
	)
}
