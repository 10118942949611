import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'babel-polyfill'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { store, persistor } from './redux/store.js'
import history from './history'
import packageJSON from '../package.json'
// import { ThemeProvider } from 'styled-components'
//import theme from './styles/styled-components/theme'
import { PersistGate } from 'redux-persist/integration/react'
import { configureInterceptors } from './interceptors'
import ScrollToTop from './components/common/scroll-to-top'
import { ToastProvider } from 'react-toast-notifications'
import theme from './styles/theme/theme'
import {  ThemeProvider } from '@mui/material/styles'



// ---------------------------------------------
//   HTTP INTERCEPTOR FOR AXIOS - REQUEST
// ---------------------------------------------
configureInterceptors()

// ---------------------------------------------
//   APP CONSTRUCT
// ---------------------------------------------

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <ToastProvider autoDismissTimeout={12000}>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </ToastProvider>
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
)

// ReactDOM.render(<App />, document.getElementById('root'));

// console.log(`mh1 : ${packageJSON.ver}`)
// console.log('envn:', window.isPROD ? 'prod' : 'uat')
// console.log(`last: ${packageJSON.last}`)
ReactDOM.render(app, document.getElementById('root'))

registerServiceWorker()
//unregister()
// hello world

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
