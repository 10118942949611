import React, {Component, Fragment, useEffect} from 'react'
import packageJSON from '../../../../package.json'
import {footerContent} from '../../../app-config/meta-data'
import HandleLinkRender from '../LinkRender'
import config from '../../../config.json'
import {gsap} from "gsap";

require('./footer.scss')

const Footer = props => {

    const myPath = window.location.pathname
    const d = new Date()
    const fullYear = d.getFullYear()

    useEffect(() => {
        gsap.fromTo(".footer", {opacity: 0}, {opacity: 1, duration: 2});
    }, [])

    return (
        <footer className="footer" name="footer">
            <div className="footerContainer">
                {footerContent.columns.map((footerTile, i) => {
                        return (
                            <div className="footerTile" key={i}>
                                <ul className="list">
                                    {footerTile.content.map((listItem, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    {listItem.type === 'title' && (
                                                        <li className='footerTitle'>
                                                            {listItem.value}
                                                        </li>
                                                    )}
                                                    {listItem.type === 'link' && (
                                                        <li>
                                                            <HandleLinkRender
                                                                link={listItem.value}
                                                            />
                                                        </li>
                                                    )}
                                                    {listItem.type === 'img' && (
                                                        <li className={`footerImg ${listItem.specialClass ? listItem.specialClass : ''}`}>
                                                            <img src={listItem.value.src} alt={listItem.value.alt}
                                                                 title={listItem.value.title}/>
                                                        </li>
                                                    )}
                                                </Fragment>
                                            )
                                        }
                                    )}
                                </ul>
                            </div>
                        )
                    }
                )}

            </div>
            <div className="footerLow">
                <div className="list">
                    {/*<div>{`v${packageJSON.ver}`}</div>*/}
                    <div>
                        <a href={`http://${config.WEBSITE_URL}`}>{config.WEBSITE_NAME}</a>
                    </div>
                    <div>
                        <a>{`© Copyright ${fullYear}`}</a>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer
