import * as actionTypes from './action-types'

const initialState = {
  hamburgerStatus:false
}

const hamburgerReducer = ( state = initialState, action ) => {
  switch(action.type){
    case actionTypes.TOGGLE_HAMBURGER:
      return{
        ...state,
        hamburgerStatus:action.data
      }
      break
    // default: return {state}


}
  return state
}

export default hamburgerReducer
